<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <!-- BEGIN: sidebar for adding timeline -->
    <sidebar class="sidebar" ref="timelineSidebar"
             :title="formData.edit ? $t('ems.timeline.edit') : $t('ems.timeline.create')"
             :subtitle="$t('ems.timeline.timelineDetails')">
      <template v-slot:sidebar-content>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('ems.common.name')+':*'" label-for="tl-name">
              <b-form-input id="tl-name" v-model="formData.name"
                            :state="!formData.edit ? formData.name !== '' && nameUnique : formData.name !== ''"
                            :placeholder="$t('ems.timeline.namePlaceholder')">
              </b-form-input>
              <b-form-text text-variant="danger" v-if="!formData.edit ? !nameUnique : false">
                {{ $t('ems.timeline.nameDuplicate') }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="!formData.edit">
            <label label-for="timeline-timezone">{{ $t('ems.timeline.timezone')}}:*</label>
            <b-form-select id="timeline-timezone" v-model="formData.timezone"
                           :options="timezoneOptions"
                           aria-describedby="timeline-timezone-help">
            </b-form-select>
            <b-form-text id="timeline-timezone-help">
              {{ $t('ems.timeline.timezoneHelp') }}
            </b-form-text>
          </b-col>
          <b-col cols="12">
            <b-form-group :label="$t('ems.common.info')+':'" label-for="tl-info">
              <b-form-input id="tl-info" v-model="formData.info"
                            :placeholder="$t('ems.timeline.infoPlaceholder')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="!formData.edit">
            <label label-for="timeline-template">{{ $t('ems.timeline.template') }}:</label>
            <b-form-select id="timeline-template" v-model="formData.template"
                           :options="templatesOptions"
                           aria-describedby="timeline-driver-help">
            </b-form-select>
            <b-form-text id="timeline-driver-help">
              {{ $t('ems.timeline.templateHelp') }}
            </b-form-text>
          </b-col>
        </b-row>
        <b-form-group>
          <b-button class="mt-4" variant="success" block v-if="!formData.edit"
                    :disabled="formData.timezone === null || formData.name === '' || !nameUnique"
                    v-on:click="onCreateTimeline()">{{ $t('ems.timeline.create') }}
          </b-button>
          <b-button class="mt-4" variant="success" block v-if="formData.edit"
                    :disabled="formData.name === ''"
                    v-on:click="onUpdateTimeline()">{{ $t('ems.timeline.save') }}
          </b-button>
        </b-form-group>
      </template>
    </sidebar>

    <!-- Secondary navigation -->
    <navbar-secondary>
      <template v-slot:items>
        <b-nav-item>
          <b-button variant="primary"
                    v-on:click="$refs.timelineSidebar.open();resetSidebar();">
            <b-icon-plus></b-icon-plus> {{ $t('ems.timeline.create') }}
          </b-button>
        </b-nav-item>
        <b-nav-item>
          <b-button variant="dark" v-b-modal.templates-dialog>
            <b-icon-layers></b-icon-layers> {{ $t('ems.timeline.templates') }}
          </b-button>
        </b-nav-item>
      </template>
    </navbar-secondary>

    <b-container class="py-4" fluid>
      <div class="search-wrapper mb-5">
        <input type="text" v-model="searchQuery" ref="search"
               :placeholder="$t('ems.common.search')"/>
      </div>
      <b-table id="data-table" hover head-variant="light" class="row-pointer"
               :items="resultQuery" :fields="tableFields"
               :per-page="perPage"
               :current-page="currentPage"
               @row-clicked="(item, _, __) => {openTimelineDetails(item.actions.uuid, item.actions.rev)}">
        <template v-slot:cell(status)="status">
          <b v-bind:class="{'text-success' :  status.value.includes('PUBLISHED'),
           'text-warning' : status.value === 'DRAFT',
           'text-primary' : status.value === 'CHANGED'  }">
            {{ $t('ems.timeline.' + status.value.toLowerCase()).toUpperCase() }}
          </b>
        </template>

        <template v-slot:cell(created)="row">
          <small>{{ row.value }}</small>
        </template>

        <template v-slot:cell(updated)="row">
          <small>{{ row.value }}</small>
        </template>

        <template v-slot:cell(actions)="row">
          <b-button variant="dark" size="sm" class="mb-1 ml-1"
                    v-on:click="openTimelineDetails(row.value.uuid, row.value.rev)">
            <b-icon-pencil></b-icon-pencil>
          </b-button>

          <b-dropdown class="mb-1 ml-1" size="sm" variant="outline-dark" right>
            <template #button-content>
              <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
            </template>
            <b-dropdown-item v-on:click="onEditTimeline(row.value.uuid)">
              <b-icon-gear-wide-connected></b-icon-gear-wide-connected> Settings
            </b-dropdown-item>
            <b-dropdown-item v-on:click="onDeleteTimeline(row.value.uuid)">
              <span class="text-danger"><b-icon-trash></b-icon-trash> Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-alert show v-if="!resultQuery.length" class="text-center">
        <b-icon-exclamation-diamond></b-icon-exclamation-diamond> {{ $t('ems.common.nothingFound') }}
      </b-alert>

      <b-pagination v-if="resultQuery.length" v-model="currentPage"
                    :total-rows="rows" :per-page="perPage"
                    aria-controls="data-table" align="center" class="mt-5">
      </b-pagination>
    </b-container>

    <b-modal id="templates-dialog"
             :title="$t('ems.timeline.availableTemplates')"
             centered size="lg" hide-footer>
      <div class="text-center">
        <b-alert :show="!templatesData.length">
          {{ $t('ems.timeline.noTemplates') }}
        </b-alert>
        <b-table hover head-variant="light" :items="templatesData">
          <template v-slot:cell(default)="row">
            <b-button variant="dark" size="sm" v-if="row.value" @click="onSetDefaultTemplate(row.value)">
              {{ $t('ems.timeline.setDefault') }}
            </b-button>
            <b-icon-check-circle v-if="!row.value" class="text-primary"></b-icon-check-circle>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button variant="danger" size="sm"
                      v-on:click="onDeleteTemplate(row.value.uuid)">
              <b-icon-trash></b-icon-trash>
            </b-button>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-alert
      v-model="loading"
      class="position-fixed fixed-bottom m-0 rounded-0 text-center"
      style="z-index: 2000;"
      variant="primary">
      <b-icon-alarm></b-icon-alarm> {{ $t('ems.common.loadingWait') }}
    </b-alert>

  </div>
</template>

<script>
  import Vue from 'vue'
  import uuid4 from 'uuid'
  import moment from 'moment-timezone'

  export default {
    components: {
      NavbarSecondary: () => import('@/components/NavbarSecondary.vue'),
      Sidebar: () => import('@/components/Sidebar.vue')
    },
    data () {
      return {
        loading: false,
        user: 'tba',
        timelines: [],
        templates: [],
        formData: {
          edit: false,
          name: '',
          info: '',
          created: null,
          updated: null,
          template: null,
          timezone: null
        },
        searchQuery: null,
        perPage: 20,
        currentPage: 1,
        tableFields: [
          {
            key: 'name',
            label: this.$t('ems.common.name'),
            sortable: true
          },
          {
            key: 'info',
            label: this.$t('ems.common.info'),
            thClass: 'd-none d-xl-table-cell',
            tdClass: 'd-none d-xl-table-cell'
          },
          {
            key: 'status',
            label: this.$t('ems.common.status'),
            sortable: true
          },
          {
            key: 'revision',
            label: this.$t('ems.timeline.revision')
          },
          {
            key: 'timezone',
            label: this.$t('ems.timeline.timezone')
          },
          {
            key: 'created',
            label: this.$t('ems.timeline.created'),
            sortable: true,
            thClass: 'd-none d-xl-table-cell',
            tdClass: 'd-none d-xl-table-cell'
          },
          {
            key: 'updated',
            label: this.$t('ems.timeline.updated'),
            sortable: true,
            thClass: 'd-none d-xl-table-cell',
            tdClass: 'd-none d-xl-table-cell'
          },
          {
            key: 'editor',
            label: this.$t('ems.timeline.editor'),
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            thClass: 'text-right',
            tdClass: 'text-right'
          }
        ]
      }
    },
    computed: {
      nameUnique () {
        let names = []
        this.timelines.forEach((c) => {
          names.push(c.name)
        })
        return !names.includes(this.formData.name)
      },
      tableData () {
        let tableData = []
        this.timelines.forEach((c) => {
          tableData.push({
            name: c.name,
            info: c.info ? c.info : '--',
            revision: c.revision,
            timezone: c.timezone,
            editor: c.author,
            status: !c.published_revision ? 'DRAFT' : c.published_revision && c.published_revision !== c.revision ? 'CHANGED' : 'PUBLISHED',
            created: moment(c.created).format('DD.MM.YYYY HH:mm:ss'),
            updated: moment(c.updated).format('DD.MM.YYYY HH:mm:ss'),
            actions: { 'uuid': c.uuid, 'rev': c.revision, 'tz': c.timezone }
          })
        })
        return tableData
      },
      resultQuery () {
        if (this.searchQuery) {
          return this.tableData.filter((item) => {
            return this.searchQuery
              .toLowerCase().split(' ')
              .every(v => item.name.toLowerCase().includes(v) ||
                item.status.toLowerCase().includes(v))
          })
        } else {
          return this.tableData
        }
      },
      rows () {
        return this.resultQuery.length
      },
      templatesData () {
        let tableData = []
        this.templates.forEach(t => {
          tableData.push({
            default: !t.is_default ? t.uuid : null,
            name: t.name,
            created: moment(t.created).format('DD.MM.YYYY HH:mm:ss'),
            actions: { uuid: t.uuid }
          })
        })
        return tableData
      },
      templatesOptions () {
        let options = [{ value: null, text: this.$t('ems.timeline.templateNotSelected') }]
        this.templates.forEach(t => {
          options.push({ value: t.uuid, text: t.name })
          if (t.is_default) {
            this.formData.template = t.uuid
          }
        })
        return options
      },
      timezoneOptions () {
        let options = [{ value: null, text: this.$t('ems.timeline.timezoneNotSelected') }]
        return options.concat(moment.tz.names())
      }
    },
    methods: {
      // === on handlers =======================================================

      openTimelineDetails (uuid, rev) {
        this.$router.push({ name: 'timelineDetails', params: { cal_uuid: uuid, rev: rev } })
      },

      onEditTimeline (calUuid) {
        let tl = this.timelines.filter(c => {
          return c.uuid === calUuid
        })
        this.formData.name = tl[0].name
        this.formData.info = tl[0].info
        this.formData.timezone = tl[0].timezone

        this.formData.edit = calUuid
        this.$refs.timelineSidebar.open()
      },

      onCreateTimeline () {
        let tl = null
        let temp = null
        this.createTimeline().then(res => {
          // push template data
          if (this.formData.template) {
            tl = res.msg.result.uuid
            temp = this.templates.find(t => {
              return t.uuid === this.formData.template
            })
          }
          if (temp) {
            (async () => {
              for (let i = 0; i < temp.tracks.length; i++) {
                await this.createTemplateTrack(tl, temp.tracks[i])
              }
            })()
          }
          this.getTimelines().then(_res => {
            this.timelines = _res.msg.result
            this.$refs.timelineSidebar.close()
          })
        })
      },

      onUpdateTimeline () {
        this.updateTimeline().then(() => {
          this.updateEditInfo().then(_ => {
            this.getTimelines().then((res) => {
              this.timelines = res.msg.result
              this.$refs.search.focus()
              this.$refs.timelineSidebar.close()
            })
          })
        })
      },

      onDeleteTimeline (uuid) {
        this.$bvModal.msgBoxConfirm(
          String(this.$t('ems.timeline.deleteQuestion')),
          {
            title: this.$t('ems.timeline.deleteHead'),
            okVariant: 'danger',
            okTitle: this.$t('ems.timeline.ok'),
            headerBgVariant: 'danger',
            headerTextVariant: 'light',
            cancelTitle: this.$t('ems.timeline.cancel'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        )
          .then(value => {
            if (value) {
              this.deleteTimeline(uuid).then((res) => {
                this.removeBrokerEvents(res.msg['broker_events'])
                this.getTimelines().then((res) => {
                  this.timelines = res.msg.result
                })
              })
            }
          })
      },

      onDeleteTemplate (uuid) {
        this.deleteTemplate(uuid).then(_ => {
          this.getTemplates().then(res => {
            this.templates = res.msg.result
            if (!this.templates.length) {
              this.formData.template = null
            }
          })
        })
      },

      onSetDefaultTemplate (uuid) {
        this.defaultTemplate(uuid).then(_ => {
          this.getTemplates().then(res => {
            this.templates = res.msg.result
          })
        })
      },

      // === scope utils =======================================================

      resetSidebar () {
        this.formData.name = ''
        this.formData.timezone = null
        this.formData.info = ''
        this.formData.edit = false
        this.formData.template = null
        this.formData.created = null
        this.formData.updated = null
      },

      makeToast (msg) {
        this.$bvToast.toast([msg], {
          title: 'TimelineAPI response',
          solid: true,
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-right'
        })
      },

      removeBrokerEvents (res) {
        return new Promise(resolve => {
          let promises = []
          res.forEach(e => {
            promises.push(
              this.$driverManager.deleteEvent(e.broker_uid)
                .then(() => {
                  console.debug('broker_res: removed', e.broker_uid)
                  e.broker_uid = null
                }, () => {
                  console.debug('broker_res: not found', e.broker_uid)
                  e.broker_uid = null
                }))
          })
          Promise.allSettled(promises).then(() => {
            resolve(res)
          })
        })
      },

      // === API calls =========================================================

      getTimelines () {
        this.loading = true
        return new Promise((resolve) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/timelines/',
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              this.loading = false
              resolve(response)
            })
        })
      },
      getTemplates () {
        return new Promise((resolve) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/templates/',
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
        })
      },
      createTimeline () {
        let body = {
          uuid: uuid4(),
          name: this.formData.name,
          info: this.formData.info,
          timezone: this.formData.timezone,
          working_hours: { start: '00:00', end: '23:59' },
          created: moment(),
          author: this.user
        }
        return new Promise((resolve) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/timelines/create', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          }).then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Timeline created')
              resolve(response)
            })
        })
      },

      updateTimeline () {
        let body = {
          uuid: this.formData.edit,
          name: this.formData.name,
          info: this.formData.info,
          working_hours: { start: '06:00', end: '21:00' }
        }
        return new Promise((resolve) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/timelines/update', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          }).then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Timeline updated')
              resolve(response)
            })
        })
      },

      updateEditInfo () {
        this.$keycloakmanager.loadUserInfo().then(res => {
          let body = {
            uuid: this.formData.edit,
            updated: moment(),
            author: res.preferred_username
          }
          return new Promise((resolve) => {
            fetch(Vue.prototype.$TIMELINE_MONGODB + '/timelines/edit-info', {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader(),
              body: JSON.stringify(body)
            }).then((resp) => resp.json())
              .then((response) => {
                resolve(response)
              })
          })
        })
      },

      deleteTimeline (uuid) {
        return new Promise((resolve) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/timelines/delete/' + uuid, {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Timeline deleted')
              resolve(response)
            })
        })
      },

      deleteTemplate (uuid) {
        return new Promise((resolve) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/templates/delete/' + uuid, {
            method: 'DELETE',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Template deleted')
              resolve(response)
            })
        })
      },

      defaultTemplate (uuid) {
        return new Promise((resolve) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/templates/default/' + uuid, {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Template is default')
              resolve(response)
            })
        })
      },

      createTemplateTrack (calUuid, data) {
        let body = {
          uuid: uuid4(),
          timeline_uuid: calUuid,
          client_uuid: data.client_uuid,
          enabled: data.enabled,
          group: data.group,
          name: data.name,
          shortcut_list: data.shortcut_list
        }

        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/tracks/create', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Track added:' + data.name)
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in addTrack', 'danger')
              reject(error)
            })
        })
      }
    },

    created () {
      this.$keycloakmanager.loadUserInfo().then(res => {
        this.user = res.preferred_username
        this.getTimelines().then((res) => {
          this.timelines = res.msg.result
          this.$refs.search.focus()
        })
        this.getTemplates().then((res) => {
          this.templates = res.msg.result
        })
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
