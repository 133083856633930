function secondsToHHMMSS (sec) {
  let hours = Math.floor(sec / 3600)
  let minutes = Math.floor((sec - (hours * 3600)) / 60)
  let seconds = Math.floor((sec - (hours * 3600)) - (minutes * 3600) / 60)

  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds

  return hours + ':' + minutes + ':' + seconds
}

function timeToSeconds (time) {
  if (time.length < 6) time += ':00'
  let t = time.split(':')
  return (+t[0]) * 60 * 60 + (+t[1]) * 60 + (+t[2])
}

function sleep (milliseconds) {
   return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function captalize (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export { secondsToHHMMSS, timeToSeconds, sleep, captalize }
