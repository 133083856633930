/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import { RRule, RRuleSet } from 'rrule'
import moment from 'moment'
import uuid4 from 'uuid4'
import { timeToSeconds, secondsToHHMMSS } from '../js/utils'

const DEFAULT_DUR = 900 // s -> 00:15:00

export default class EventPrototype {
  constructor (method, timezone) {
    this._method = method
    this._configured = false

    this._uuid = uuid4()
    this._date = moment().toDate()
    this._time = moment().format('HH:00:00')
    this._timezone = timezone
    this._rruleSet = null

    this.min_duration = DEFAULT_DUR
    this._argsKeys = []
    this._args = {}
    this._args_mixins = {}
    this._next_ref = null
    this._prev_ref = null
    this._group = null

    // for edit events
    this._event_data = { event: null, prev: null, next: null, group: [] }
    this._group_edit_enabled = false
  }

  get timeError () {
    if (!this.inheritPrototype || this.groupEdit) return false

    let e = this.inheritPrototype
    let next = this.getRefEvent(e.nextRef)
    let prev = this.getRefEvent(e.prevRef)
    // console.debug('references: prev:', prev, 'e:', e, 'next:', next)

    if (prev && !prev.durationFixed && this.begin < prev.begin) {
      // console.error('Left max reached')
      return true
    } else if (next && this.begin > next.begin) {
      // console.error('Right max reached')
      return true
    } else if (prev && prev.durationFixed) {
      let _prev = this.getRefEvent(prev.prevRef)
      if (_prev) {
        let diff = this.begin.diff(e.begin, 'seconds')
        let newPrevBegin = moment(prev.begin).add(diff, 'seconds')
        if (newPrevBegin < _prev.begin) {
          // console.error('Left max of connected fixed dur event reached')
          // console.debug(newPrevBegin.format('HH:mm:ss'), _prev.begin.format('HH:mm:ss'))
          return true
        }
      }
    } else if (next && next.durationFixed) {
      console.warn('Next max check not implemented')
    }
    return false
  }

  get groupEditAvailable () {
    return this._event_data && this._event_data.group.length
  }

  get groupEdit () {
    return this._group_edit_enabled
  }

  set groupEdit (val) {
    this._group_edit_enabled = val
  }

  get inheritPrototype () {
    return this._event_data.event
  }

  set nextRef (value) {
    this._next_ref = value
  }

  get nextRef () {
    return this._next_ref
  }

  set prevRef (value) {
    this._prev_ref = value
  }

  get prevRef () {
    return this._prev_ref
  }

  set group (value) {
    this._group = value
  }

  get group () {
    return this._group
  }

  get inheritGroupEvents () {
    return this._event_data.group
  }

  get isValid () {
    if (this.timeError) {
      return false
    }

    let valid = true
    this.argsKeys.forEach(v => {
      if (v in this.args) {
        valid = this.args[v] !== ''
      } else {
        valid = false
      }
    })
    return valid
  }

  get begin () {
    let day = moment.utc(this.date).format('YYYY-MM-DD')
    // console.log('MOM:', moment.utc(day + 'T' + this.time).format())
    return moment.utc(day + 'T' + this.time)
  }

  get method () {
    return this._method.method
  }

  set method (value) {
    this._method = value
  }

  get uuid () {
    return this._uuid
  }

  set uuid (value) {
    this._uuid = value
  }

  get date () {
    return this._date
  }

  set date (value) {
    this._date = value
  }

  get time () {
    return this._time
  }

  set time (value) {
    this._time = value
  }

  get timezone () {
    return this._timezone
  }

  getRefEvent (ref) {
    let event = null
    this._event_data.group.forEach(e => {
      if (e.uuid === ref) {
        event = e
      }
    })
    return event
  }

  get rruleSet () {
    return this._rruleSet
  }

  set rruleSet (rruleString) {
    let rrule = RRule.parseString(rruleString)
    rrule.dtstart = this.begin.toDate()
    this._rruleSet = new RRuleSet()
    this._rruleSet.rrule(new RRule(rrule))
  }

  get args () {
    return this._args
  }

  set args (value) {
    this._args = value
  }

  get argsSet () {
    return Object.values(this._args).length
  }

  get argsMixins () {
    return this._args_mixins
  }

  set argsMixins (value) {
    this._args_mixins = value
  }

  get argsKeys () {
    return this._argsKeys
  }

  set argsKeys (value) {
    this._argsKeys = value
  }

  get configured () {
    return this._configured
  }

  set configured (value) {
    this._configured = value
  }

  get duration () {
    return this.argsMixins['duration']
      ? this.argsMixins['duration']
      : this.min_duration
  }

  set duration (value) {
    this.argsMixins['duration'] = timeToSeconds(value)
  }

  get durationHHMMSS () {
    // used from b-time-picker
    return secondsToHHMMSS(this.argsMixins['duration'])
  }

  set durationHHMMSS (value) {
    // used from b-time-picker
    this.argsMixins['duration'] = timeToSeconds(value)
  }

  get durationFixed () {
    let thisFixed = 'duration_fixed' in this.argsMixins && this.argsMixins['duration_fixed']
    return thisFixed || this._isInheritRefDurFixed()
  }

  _isInheritRefDurFixed () {
    if (!this.inheritPrototype) return false
    let prevRef = this._event_data.event ? this._event_data.event.prevRef : null
    if (prevRef) {
      let e = this.getRefEvent(prevRef)
      return 'duration_fixed' in e.argsMixins &&
        e.argsMixins['duration_fixed']
    }
  }

  updateDateTime (value) {
    // value is new moment
    this._date = value.toDate()
    this._time = value.format('HH:mm:ss')
  }

  get hasDurationArgs () {
    return 'duration_fixed' in this.argsMixins
  }

  setPrototypeDataFromEvent (event, groupEvents) {
    this._uuid = event.uuid
    this.date = event.begin.toDate()
    this.time = event.begin.format('HH:mm:ss')
    this.group = event.group

    this.argsKeys = Object.keys(event.args)
    this.args = event.args
    this.argsMixins = event.argsMixins

    // // set mixins for connected event with non fixed duration
    if (event.nextRef && !this.durationFixed && !this.argsMixins.duration) {
      this.argsMixins = {
        'duration_fixed': false,
        'duration': moment(event.begin).add(event.nextDiff)
          .diff(moment(event.begin), 'seconds')
      }
    }

    this._event_data = { event: event, group: groupEvents }
    console.debug('prototype data set:', this)
  }

  getUpdateList () {
    let updateList = []
    let diff = this.begin.diff(this.inheritPrototype.begin)
    // apply data for selected event
    this.inheritPrototype.begin = this.begin
    this.inheritPrototype.args = this.args
    updateList.push(this.inheritPrototype)

    if (this.groupEdit) {
      // apply diffs for all previous events
      if (this.inheritPrototype.prevRef) {
        let prev = this.getRefEvent(this.inheritPrototype.prevRef)
        prev.addTimeDiff(diff)
        updateList.push(prev)
        this._addRecursivePrevBegin(prev, diff, updateList)
      }
      // apply duration for all next events
      if (this.inheritPrototype.nextRef && this.duration) {
        let next = this.getRefEvent(this.inheritPrototype.nextRef)
        next.begin = moment(this.begin).add(this.duration, 'seconds')
        updateList.push(next)
        if (next.nextRef) {
          this._addRecursiveNextBegin(next, updateList)
        }
      }
    }

    // clean_args mixins
    updateList.forEach(e => {
      if (e.nextRef && !e.hasDurationArgs && !e.durationFixed) {
        e.argsMixins = {}
      }
    })

    return updateList
  }

  _addRecursiveNextBegin (next, updateList) {
    if (next.durationFixed) {
      let _next = this.getRefEvent(next.nextRef)
      if (_next) {
        _next.begin = moment(next.begin).add(next.argsMixins.duration, 'seconds')
        updateList.push(_next)
      }
      if (_next && _next.nextRef) {
        this._addRecursiveNextBegin(_next, updateList)
      }
    }
  }

  _addRecursivePrevBegin (prev, diff, updateList) {
    if (prev.durationFixed) {
      prev.addTimeDiff(diff)
      updateList.push(prev)
    }
    if (prev.prevRef) {
      let _prev = this.getRefEvent(prev.prevRef)
      this._addRecursivePrevBegin(_prev, diff, updateList)
    }
  }
}
